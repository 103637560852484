import * as t from 'io-ts';

const PointGeometryType = t.type({
    type: t.literal('Point'),
    coordinates: t.tuple([t.number, t.number])
}, 'PointGeometry');
const PolygonGeometryType = t.type({
    type: t.literal('Polygon'),
    coordinates: t.tuple([t.array(t.tuple([t.number, t.number]))])
}, 'PolygonGeometry');
const MultiPolygonGeometryType = t.type({
    type: t.literal('MultiPolygon'),
    coordinates: t.array(t.tuple([t.array(t.tuple([t.number, t.number]))]))
}, 'MultiPolygonGeometry');

const GeojsonPropsType = t.type({
    name: t.string,
    fips: t.string
});

export const GeojsonFeatureType = t.type({
    type: t.literal('Feature'),
    geometry: t.union([
        PointGeometryType,
        PolygonGeometryType,
        MultiPolygonGeometryType
    ]),
    properties: GeojsonPropsType
}, 'GeojsonFeature');

export const GeojsonFeatureCollectionType = t.type({
    type: t.literal('FeatureCollection'),
    features: t.array(GeojsonFeatureType)
}, 'GeojsonFeatureCollection');
