import { isLeft } from 'fp-ts/lib/These';
import { Type } from 'io-ts';

function parse<T, A = T>(type: Type<T, A>, value: unknown): T {
    const result = type.decode(value);
    if (isLeft(result)) {
        const errors = result.left;
        console.log('errors', errors);
        throw new Error(`Parse error: \n${errors.map(error => `${type.name}: ${error.message}: ${
            error.context.map(el => el.key).join('.')
        }: ${JSON.stringify(error.value)}`).join('\n')}`);
    }
    return result.right;
}

export function createParseFunction<T, A = T>(type: Type<T, A>) {
    return (value: unknown): T => parse(type, value);
}
