import Vue from 'vue';
import { AppModel } from './AppModel';

declare module 'vue/types/options' {
    interface ComponentOptions<V extends Vue> {
        appModel?: AppModel;
    }
}

declare module 'vue/types/vue' {
    interface Vue {
        $appModel: AppModel;
    }
}
Vue.mixin({
    beforeCreate() {
        this.$appModel = this.$options.appModel || (this.$parent && this.$parent.$appModel);
    }
});
