import { ParsedMetricMetadata } from '@/services/DataService/parsers';

import { Metric } from './Metric';
import { NumericMetric } from './NumericMetric';
import { CategoricalMetric } from './CategoricalMetric';

type AnyMetric = NumericMetric | CategoricalMetric;

export function metricFromMetadata(metadata: ParsedMetricMetadata): AnyMetric {
    switch (metadata.type) {
    case 'nominal':
        return new NumericMetric(metadata);
    case 'percent':
        return new NumericMetric(metadata);
    case 'categorical':
        return new CategoricalMetric(metadata);
    default:
        // TS says this is impossible so we have to cast away from `never` to use the metadata
        throw new Error(`Invalid metric type: ${(metadata as ParsedMetricMetadata).type}`);
    }
}

export { Metric, AnyMetric, NumericMetric, CategoricalMetric };
