// Import this file first to guarantee Vue.use is called before any other files
// get a chance to evaluate

import Vue from 'vue';
import CompositionDecorators from '@dha/vue-composition-decorators';
import { loadToggles } from '@dha/feature-toggles';
import screens from './screens';

Vue.use(CompositionDecorators);
Vue.use(screens);
loadToggles();
