import { ParsedMetricMetadata } from '@/services/DataService/parsers';
import { scaleQuantize } from 'd3-scale';
import { ColorScale, LegendValue, Metric, QuantizeScale } from './Metric';

/* eslint-disable class-methods-use-this */
export class NullMetric extends Metric {
    constructor() {
        super({} as ParsedMetricMetadata);
    }

    readonly quantizeBuckets = 5;
    readonly quantizeColors = ['#fff', '#fff', '#fff', '#fff', '#fff'];
    get title() {
        return '';
    }
    get description() {
        return '';
    }
    isGeoLevelAvailable() {
        return false;
    }

    format() {
        return '';
    }
    groupedFormat() {
        return '';
    }
    getDomain(): [number, number] {
        return [0, 1];
    }
    getLegendValues(): LegendValue[] {
        return [];
    }
    getColorScale(): ColorScale {
        return scaleQuantize<string, string>();
    }
    getQuantizeScale(): QuantizeScale {
        return scaleQuantize<number>();
    }
    valuesBelowExtent(): boolean {
        return false;
    }
    valuesAboveExtent(): boolean {
        return false;
    }
    getDisplayValue(): string {
        return '';
    }
    getColor(): string {
        return '#fff';
    }
}
